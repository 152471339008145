import React, { useEffect, useState } from 'react';
import { SimpleGrid, Box, Stack, Center, Text, GridItem, SkeletonCircle } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import api from '../api';
import { ListingType, badgeLookupTable } from '../constants';
import { useAuth } from '../AuthContext';

import PageGrid from '../components/PageGrid';
import InfoCard from '../components/InfoCard';
import DataTable from '../components/ListingsTable';
import DataTabs from '../components/XTabs';
import ScoreCard from '../components/ScoreCard';
import IncreaseCTACard from '../components/IncreaseCTACard';
import NewsCard from '../components/NewsCard';
import XButton from '../components/XButton';
import Badge from '../components/Badge';
import XBorder from '../components/XBorder';

import { ReactComponent as AllBadges } from '../assets/all-badges.svg';

const HomePage: React.FC = () => {
  const { user, isAffiliate } = useAuth();
  const [data, setData]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const latestBadge = user?.userBadges?.length ? user?.userBadges[user?.userBadges.length - 1] : null;

  const affiliate = isAffiliate();

  let tabData = [
    {
      label: 'Available',
      content: ListingType.Available,
    },
    {
      label: 'Closed',
      content: ListingType.Closed,
    },
  ];

  if (!affiliate) {
    tabData = [
      {
        label: 'Available',
        content: ListingType.Available,
      },
      {
        label: 'Wishlist',
        content: ListingType.Wishlist,
      },
      {
        label: 'Disposing',
        content: ListingType.Disposing,
      },
    ];
  }

  const [reward, setReward] = useState({
    icon: '',
    text: '',
    closedLoop: '',
  });

  const fetchAchievements = async () => {
    try {
      const { data } = await api.get('/rewards/congratulations');
      setReward(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [filter, setFilter] = useState();
  const fetchData = async () => {
    try {
      setLoading(true);

      let response;
      if (affiliate) {
        const endpointURL = filter === ListingType.Closed ? `/listings/shared-affiliates/closed` : `/listings/shared-affiliates`;

        response = await api.get(endpointURL, {
          params: { limit: 4 },
        });
      } else {
        response = await api.get(`/listings`, {
          params: { limit: 4, ...(filter ? { listingTabType: filter } : {}) },
        });
      }

      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filter) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    fetchAchievements();
  }, []);

  const handleTabChange = (index: number, content: ListingType) => {
    // @ts-ignore
    setFilter(content);
  };

  const badgeDescription: string = latestBadge ? badgeLookupTable[latestBadge.name] : '';
  const splitBadgeDescripion = badgeDescription ? badgeDescription.split(latestBadge.name) : ['You have earned your ', ' badge!'];
  return (
    <PageGrid
      sideBar={
        <GridItem minWidth={'250px'}>
          <Stack gap="6">
            <ScoreCard />
            {!isAffiliate() && <NewsCard />}
            {isAffiliate() && filter !== ListingType.Closed && <IncreaseCTACard listings={data} />}
          </Stack>
        </GridItem>
      }
    >
      <SimpleGrid columns={2} spacing={4} paddingBottom={6}>
        <InfoCard
          title="Achievements"
          tooltipTitle={'Badges'}
          tooltipText={'Track the badges that you have earnt and see how you’re making a difference to a sustainable future!'}
          light={true}
        >
          <Stack direction={'row'}>
            <Box width={'100px'}>
              {latestBadge && <Badge iconUrl={latestBadge.icon} name={latestBadge.name} size="100px" />}
              {!latestBadge && <AllBadges width={'100px'} />}
            </Box>
            <Center fontSize={'14px'}>
              {latestBadge && (
                <Text>
                  {splitBadgeDescripion[0]} <strong>{latestBadge.name}</strong>
                  {splitBadgeDescripion[1]}
                </Text>
              )}{' '}
              {!latestBadge && (
                <Text>
                  Start listing assets that you no longer need or search for assets to repurpose instead of buying new! You can work towards
                  achieveing these badges!
                </Text>
              )}
            </Center>
          </Stack>
        </InfoCard>
        <InfoCard title="Congratulations" tooltipTitle="Close the loop!" tooltipText={reward ? reward.text : ''}>
          <Stack direction={'row'} pl="1.5" height={'100%'}>
            {reward && (
              <Box width={'100px'}>
                <SkeletonCircle size="100px" isLoaded={!!reward}>
                  {reward.icon && <Badge iconUrl={reward.icon} name="" size="100px" />}
                  {reward.closedLoop && (
                    <Center
                      height={'100px'}
                      minWidth={'100px'}
                      backgroundImage={'/ellipse-232.png'}
                      backgroundRepeat={'no-repeat'}
                      fontSize={'40px'}
                    >
                      {reward.closedLoop}
                    </Center>
                  )}
                </SkeletonCircle>
              </Box>
            )}
            <Center fontSize={'14px'}>
              <Text>{reward.text}</Text>
            </Center>
          </Stack>
        </InfoCard>
      </SimpleGrid>

      <DataTabs data={tabData} onTabChange={handleTabChange} syncWithUrl>
        <Box>
          {filter && <DataTable data={data} loading={loading} onItemChanged={fetchData} listingType={filter} loaded={true}></DataTable>}
        </Box>
        {data && data.length > 0 && <XBorder mb={3} />}
        <Box display={'flex'} justifyContent={'center'} pt={2} pb={4}>
          <XButton as={NavLink} variant="wide" size={'lg'} to={'/marketplace'}>
            Explore your Marketplace
          </XButton>
        </Box>
      </DataTabs>
    </PageGrid>
  );
};

export default HomePage;
