import React, { useEffect, useState, useCallback } from 'react';
import { Card, CardBody, useDisclosure } from '@chakra-ui/react';

import api from '../../api';
import { Utils } from '../../services';
import { UserType } from '../../constants';

import { AdminDataTable, Column } from '../../components/AdminDataTable';
import { useToastContext } from '../../ToastContext';
import { ModalDialog } from '../../components/XModal';
import { NewsForm } from '../../components/NewsForm';
import { AddButton } from '../../components/AddButton';
import { useAuth } from '../../AuthContext';

export const AdminNews: React.FC = () => {
  const { user } = useAuth();
  const [data, setData]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteing, setDeleteing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);

  const { isOpen: isAddNewOpen, onOpen: onAddNewOpen, onClose: onAddNewClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const toast = useToastContext();

  const fetchData = useCallback(async () => {
    try {
      if (page === 1) {
        setLoading(true);
        setAllLoaded(false);
      }

      const apiUrl = user?.userType === UserType.XsilioAdmin ? `/news/platform` : `/news/organisation`;
      const response = await api.get(apiUrl);

      const mappedData = response.data.data.map(({ id, title, image }: any) => ({
        id,
        name: title,
        image,
      }));

      setAllLoaded(response.data.pagination.totalPages === 0 || response.data.pagination.totalPages === page);

      if (page > 1) {
        setData([...data, ...mappedData]);
      } else {
        setData(mappedData);
        setLoading(false);
      }
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
      setLoading(false);
    }
  }, [page]);

  const fetchMoreData = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: Column[] = [
    {
      header: 'Article',
      accessor: 'name',
      align: 'left',
    },
  ];

  const deleteArticle = async (id: string) => {
    setDeleteing(true);
    try {
      await api.delete(`/news/${id}`);
      toast('Content removed', 'Content has been removed successfuly', 'success');
      fetchData();
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    setDeleteing(false);
  };

  const addArticle = async (deptData: any) => {
    setSaving(true);
    try {
      const { data } = await api.post(`/news`, {
        ...Utils.cleanPayload(deptData),
      });

      setSaving(false);
      toast('Successfully published', 'Article has been published successfuly', 'success');
      return data;
    } catch (error: any) {
      setSaving(false);
      return error.response;
    }
  };

  const handleDone = () => {
    fetchData();
    onAddNewClose();
    onEditClose();
  };

  const [toEdit, setToEdit] = useState(null);

  const editArticle = async (formData: any) => {
    setSaving(true);
    try {
      const { id, ...payload } = formData;
      const { data } = await api.patch(`/news/${id}`, payload);
      toast('Successfully updated', 'Article has been updated successfuly', 'success');
      setSaving(false);
      return data;
    } catch (error: any) {
      setSaving(false);
      return error.response;
    }
  };

  const handleEditClick = async ({ id }: any) => {
    const response = await api.get(`/news/${id}`);

    setToEdit(response.data.data);
    onEditOpen();
  };

  return (
    <>
      <ModalDialog title={`Add New Article`} isOpen={isAddNewOpen} onClose={onAddNewClose}>
        <NewsForm onSubmit={addArticle} saving={saving} onDone={handleDone} />
      </ModalDialog>
      <ModalDialog title={`Edit Article`} isOpen={isEditOpen} onClose={onEditClose}>
        <NewsForm onSubmit={editArticle} saving={saving} news={toEdit} onDone={handleDone} />
      </ModalDialog>
      <Card>
        <CardBody>
          <AddButton onClick={onAddNewOpen} />
          <AdminDataTable
            data={data}
            loading={loading}
            columns={columns}
            deleteing={deleteing}
            onDelete={deleteArticle}
            onEditClick={handleEditClick}
            enableEdit
            enableDelete
            loaded={allLoaded}
            onInfiniteScroll={fetchMoreData}
          ></AdminDataTable>
        </CardBody>
      </Card>
    </>
  );
};

export default AdminNews;
