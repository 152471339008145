import React, { useState, useEffect } from 'react';
import {
  Skeleton,
  Stack,
  Button,
  LinkBox,
  Table,
  Thead,
  Tbody,
  Avatar,
  Tr,
  Th,
  Td,
  Box,
  BoxProps,
  TableContainer,
  LinkOverlay,
  SkeletonCircle,
  useDisclosure,
  IconButton,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ReactComponent as IcnPlus } from '../icons/icn-plus.svg';
import { ReactComponent as IcnDelete } from '../icons/icn-delete.svg';
import { ReactComponent as IcnEdit } from '../icons/icn-edit.svg';
import { ReactComponent as IcnPublish } from '../icons/icn-publish.svg';

import { ListingType } from '../constants';
import api from '../api';
import { Utils } from '../services';
import { useToastContext } from '../ToastContext';
import { useAuth } from '../AuthContext';

import ListingForm from './ListingForm';
import ModalDialog from './XModal';
import XBorder from './XBorder';
import ConfirmDialog from './ConfirmDialog';

interface Props extends BoxProps {
  data: any;
  loading: boolean;
  noActions?: boolean;
  children?: React.ReactNode;
  onItemChanged: () => void;
  onInfiniteScroll?: () => void;
  listingType: ListingType;
  emptyMessage?: string;
  wishlist?: boolean;
  loaded?: boolean;
}

const formatDate = (date: string) => {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleDateString();
};

const isEditable = (listingType: ListingType) => ListingType.Drafts === listingType;

export const ListingsTable: React.FC<Props> = ({
  data,
  loading,
  onItemChanged,
  listingType,
  noActions = false,
  wishlist,
  onInfiniteScroll,
  loaded,
  ...wrapperProps
}) => {
  const { isAffiliate } = useAuth();
  const skeletonData = [{}, {}, {}, {}];
  const toast = useToastContext();

  const [deleting, setDeleteing] = useState(false);
  const [deleteingListing, setDeleteingListing]: any = useState(null);
  const [publishing, setPublishing] = useState(false);
  const [publishingListing, setPublishingListing]: any = useState(null);
  const [editingListing, setEditingListing]: any = useState(null);
  const [emptyMessage, setEmptyMessage]: any = useState('');

  const { isOpen: isListingFormDialogOpen, onOpen: onListingFormDialogOpen, onClose: onListingFormDialogClose } = useDisclosure();
  const { isOpen: isDeleteDialogOpen, onOpen: onDeleteDialogOpen, onClose: onDeleteDialogClose } = useDisclosure();
  const { isOpen: isPublishDialogOpen, onOpen: onPublishDialogOpen, onClose: onPublishDialogClose } = useDisclosure();

  const handleFormSubmitted = () => {
    onItemChanged();
    onListingFormDialogClose();
  };

  // TODO add types
  const handleDeleteClick = (listing: any) => {
    setDeleteingListing(listing);
    onDeleteDialogOpen();
  };

  const handleEditClick = async (listing: any) => {
    const {
      data: { data },
    } = await api.get(`/listings/${listing.id}`);

    setEditingListing(data);
    onListingFormDialogOpen();
  };
  const handlePublishClick = (listing: any) => {
    setPublishingListing(listing);
    onPublishDialogOpen();
  };

  const deleteListing = async () => {
    if (deleteingListing === null) {
      return;
    }

    setDeleteing(true);

    try {
      await api.delete(`/listings/${deleteingListing.id}`);
    } catch (error: any) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }

    handleDeleteDialogClose();
    setDeleteing(false);
    onItemChanged();
  };

  const handleDeleteDialogClose = () => {
    setDeleteingListing(null);
    onDeleteDialogClose();
  };

  const handlePublishDialogClose = () => {
    setPublishingListing(null);
    onPublishDialogClose();
  };

  const publishListing = async () => {
    if (publishingListing === null) {
      return;
    }

    setPublishing(true);

    try {
      await api.patch(`/listings/${publishingListing.id}`, {
        publish: true,
      });
      toast && toast('Listing published', `Listing successfuly published`);
    } catch (error: any) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }

    setPublishing(false);
    handlePublishDialogClose();
    onItemChanged();
  };

  const handleListingFormDialogClose = () => {
    setEditingListing(null);
    onListingFormDialogClose();
  };

  const handleRemoveFromWishlist = async (listing: any) => {
    try {
      await api.delete(`/listings/wishlist/${listing.id}`);
      onItemChanged();
    } catch (error: any) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  useEffect(() => {
    const getEmptyMessage = () => {
      let retVal = '';

      if (listingType === ListingType.Wishlist) {
        retVal = 'There are currently no listings available in your Wishlist.';
      } else if (listingType === ListingType.Closed) {
        retVal = 'There are currently no closed listings to view.';
      } else if (listingType === ListingType.Drafts) {
        retVal = 'There are currently no listings in your drafts.';
      } else {
        retVal = 'There are currently no listings available. Add a new listing for others to view.';
      }
      return retVal;
    };

    setEmptyMessage(getEmptyMessage());
  }, [listingType]);

  const fetchMoreData = () => {
    if (onInfiniteScroll) {
      onInfiniteScroll();
    }
  };

  return (
    <Box {...wrapperProps}>
      <ConfirmDialog
        title="Delete listing?"
        message="Are you sure you want to delete this listing?"
        isOpen={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirm={deleteListing}
        isConfirming={deleting}
        confirmText="Delete"
      />

      <ConfirmDialog
        title="Publish listing?"
        message="Are you sure you want to publish this listing?"
        isOpen={isPublishDialogOpen}
        onClose={handlePublishDialogClose}
        onConfirm={publishListing}
        isConfirming={publishing}
        confirmText="Publish"
      />

      <ModalDialog
        title={editingListing ? `Edit your listing` : `Add new listing`}
        isOpen={isListingFormDialogOpen}
        onClose={handleListingFormDialogClose}
      >
        <ListingForm onSubmitted={handleFormSubmitted} listing={editingListing} />
      </ModalDialog>

      <InfiniteScroll
        dataLength={data ? data.length : 0}
        next={fetchMoreData}
        hasMore={!loaded}
        loader={<Box textAlign="center">{!loading && <Spinner pb="2" />}</Box>}
      >
        <TableContainer>
          <Table borderBottomRadius="30px" padding="0">
            <Thead>
              <Tr border={'none'}>
                <Th pl="0" border={'none'} fontSize={'10px'} textAlign={'start'} pt={3} pb={4}>
                  {noActions && <span>Listing</span>}
                  {!noActions && (
                    <Box>
                      {!isAffiliate() && (
                        <Button variant={'ghost'} onClick={onListingFormDialogOpen}>
                          <Box pr={3}>
                            <IcnPlus />
                          </Box>{' '}
                          Add new
                        </Button>
                      )}
                      {/* {listingType === ListingType.Available && (
                      <Button variant={'ghost'} onClick={onFilterOpen} pr={0}>
                        <Box pr={3}>
                          <IcnFilter />
                        </Box>{' '}
                        Filter
                      </Button>
                    )} */}
                    </Box>
                  )}
                </Th>
                <Th border={'none'} fontSize={'10px'}>
                  Location
                </Th>
                {!isEditable(listingType) && (
                  <>
                    <Th border={'none'} fontSize={'10px'} textAlign={'center'}>
                      Quantity
                    </Th>
                    <Th border={'none'} fontSize={'10px'} textAlign={'center'}>
                      impactX / Item
                    </Th>
                    <Th border={'none'} textAlign={'end'} fontSize={'10px'}>
                      Expiry date
                    </Th>
                  </>
                )}
                {isEditable(listingType) && (
                  <>
                    <Th border={'none'} fontSize={'10px'} textAlign={'center'}>
                      Delete
                    </Th>
                    <Th border={'none'} fontSize={'10px'} textAlign={'center'}>
                      Edit
                    </Th>
                    <Th border={'none'} fontSize={'10px'} textAlign={'center'}>
                      Publish
                    </Th>
                  </>
                )}
                {wishlist && (
                  <Th border={'none'} fontSize={'10px'} textAlign={'center'}>
                    Remove from <br /> wishlist
                  </Th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {!loading && (!data || (data && data.length === 0)) && (
                <>
                  <Tr>
                    <Td colSpan={wishlist ? 6 : 5} textAlign={'center'} pb={0} pt={0} px={0}>
                      <XBorder mb={0} mt={0} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={wishlist ? 6 : 5} textAlign={'center'} className="">
                      <Text py={3}>
                        {emptyMessage || 'There are currently no listings available. Add a new listing for others to view.'}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={wishlist ? 6 : 5} textAlign={'center'} pb={5} pt={0} px={0}>
                      <XBorder mb={0} mt={0} />
                    </Td>
                  </Tr>
                </>
              )}
              {loading &&
                skeletonData.map((item: any, index: number) => (
                  <Tr key={index} className="x-border">
                    <Td pl="0" pr="5" borderBottom="none">
                      <Stack direction={'row'} display={'flex'} alignItems="center">
                        <SkeletonCircle size="48px" mr={1} />
                        <Skeleton height="16px" width={'calc(95% - 48px)'} />
                      </Stack>
                    </Td>

                    <Td borderBottom="none">
                      <Skeleton height="16px" fontSize={'14px'} width={'100%'} textAlign={'center'}>
                        {' '}
                      </Skeleton>
                    </Td>
                    <Td borderBottom="none" textAlign={'center'}>
                      <Skeleton height="16px" fontSize={'14px'} width={'100%'} textAlign={'center'}>
                        {' '}
                      </Skeleton>
                    </Td>
                    <Td borderBottom="none" fontSize={'14px'} textAlign={'end'}>
                      <Skeleton height="16px"> </Skeleton>
                    </Td>
                    <Td borderBottom="none" fontSize={'14px'} textAlign={'end'}>
                      <Skeleton height="16px"> </Skeleton>
                    </Td>
                    {wishlist && (
                      <Td borderBottom="none">
                        <Skeleton height="16px"> </Skeleton>
                      </Td>
                    )}
                  </Tr>
                ))}
              {!loading &&
                data &&
                data.length > 0 &&
                data.map((item: any, index: number) => (
                  <Tr key={index} className="x-border">
                    <Td pl="0" pr="5" borderBottom="none">
                      <LinkBox>
                        <LinkOverlay as={NavLink} to={`/marketplace/${item.id}`}>
                          <Stack direction={'row'}>
                            <Avatar size="sm" src={item.images?.length > 0 ? item.images[0] : '/placeholder.jpg'} />
                            <Box fontWeight={600} pl={1} display={'flex'} alignItems="center" maxWidth={'200px'}>
                              <Box overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                                {item.title}
                              </Box>
                            </Box>
                          </Stack>
                        </LinkOverlay>
                      </LinkBox>
                    </Td>
                    <Td borderBottom="none" maxWidth={'100px'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                      {item.location?.city}
                    </Td>
                    {!isEditable(listingType) && (
                      <>
                        <Td borderBottom="none" textAlign={'center'}>
                          {item.quantity}
                        </Td>
                        <Td borderBottom="none" textAlign={'center'}>
                          {item.score}
                        </Td>
                        <Td borderBottom="none" fontSize={'14px'} textAlign={'end'}>
                          {formatDate(item.expirationDate)}
                        </Td>
                      </>
                    )}
                    {isEditable(listingType) && (
                      <>
                        <Td borderBottom="none" textAlign={'center'}>
                          <IconButton
                            onClick={() => handleDeleteClick(item)}
                            aria-label="Delete listing"
                            size={'sm'}
                            icon={<IcnDelete />}
                          />
                        </Td>
                        <Td borderBottom="none" textAlign={'center'}>
                          <IconButton onClick={() => handleEditClick(item)} aria-label="Edit listing" size={'sm'} icon={<IcnEdit />} />
                        </Td>
                        <Td borderBottom="none" fontSize={'14px'} textAlign={'center'}>
                          <IconButton
                            onClick={() => handlePublishClick(item)}
                            aria-label="Publish listing"
                            size={'sm'}
                            icon={<IcnPublish />}
                            isDisabled={listingType !== ListingType.Drafts}
                          />
                        </Td>
                      </>
                    )}
                    {wishlist && (
                      <Td borderBottom="none" textAlign={'center'}>
                        <IconButton
                          onClick={() => handleRemoveFromWishlist(item)}
                          aria-label="Remove from wishlist"
                          size={'sm'}
                          icon={<IcnDelete />}
                        />
                      </Td>
                    )}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </Box>
  );
};

export default ListingsTable;
